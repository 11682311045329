import React from 'react'
import Post from './Post'

const Feedposts = ({posts,setPosts,setFriendsProfile,images}) => {
  return (
    <div className='feedposts'>
        {posts ? posts.map((post)=>(
            <Post 
              images={images ? images : null}
              posts={posts}
              post ={post}
              setPosts={setPosts}
              key={post.id} 
              setFriendsProfile={setFriendsProfile}
            />
        )) : null}
    </div>
  )
}

export default Feedposts